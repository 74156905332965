import * as Mutations from "./mutation-types"
import * as Actions from "./action-types"
import * as api from "@/api/api"
import * as Helpers from "@/helpers"
import { Tabs } from "@/constants/appConstants"

const defaultProgramsQuery = {
    start_date: "",
    end_date: "",
    pub_start_date: "",
    pub_end_date: "",
    searchForPubDate: false,
    with_wildcards: true,
    provider_keys: [],
    show_all_content_with_traffic_in_period: false,
    genres: {
        genre_keys: [],
        genres: [],
    },
}

const state = {
    programsInitStatus: "NOT_LOADED",
    programsQuery: {
        ...defaultProgramsQuery,
    },
    programsQueryForAPI: {},
    programsExportQuery: {
        include_detail_rows: true,
        include_header: true,
    },
    programsResults: {
        status: "NOT_LOADED",
    },
    programsSummaryResults: {
        status: "NOT_LOADED",
    },
    programsSelectedResults: [],
    programsHaveSelectedRow: false,
    programsSummary: [],
    programsColumnTypes: [],
    programsDefaultColumns: [],
    programsAllWithStarSelected: true,
    programsWeekdayNumbers: [],
    programsProviders: [],
}

const convertFromQueryToQueryForAPI = (q, showConsumtionPeriodProgram) => {
    // transformera interna queryObjektet till ett som passar vår API
    let channels = JSON.parse(localStorage.getItem("savedChannels")) ? JSON.parse(localStorage.getItem("savedChannels")) : {}
    channels = channels["getProgramsQuery"]
    let storedGenres = JSON.parse(localStorage.getItem("genres")) ? JSON.parse(localStorage.getItem("genres")) : {}
    let genres = storedGenres["getProgramsQuery"] ? storedGenres["getProgramsQuery"] : {}

    const defaultProgramsQueryForAPI = {
        columns: [],
        measures: [],
        provider_keys: q.provider_keys,
        content_keys: q.content_keys ?? null,
        genres: genres,
        channel_codes: channels ? Object.keys(channels).reduce((prev, key) => [...prev, ...channels[key]], []) : [],
        with_wildcard: q.with_wildcards,
        search_text: q.title,
    }
    const storedTargets = localStorage.getItem("targetGroups") ? JSON.parse(localStorage.getItem("targetGroups")) : {}
    let targetGroupsPrograms = storedTargets["getProgramsQuery"]

    const APIQuery = {
        ...defaultProgramsQueryForAPI,
        start_date: q.start_date ?? null,
        end_date: showConsumtionPeriodProgram ? q.end_date : q.start_date,
        pub_start_date: q.searchForPubDate ? q.pub_start_date : null,
        pub_end_date: q.searchForPubDate ? q.pub_end_date : null,
        showPubDate: q.searchForPubDate,
        measures: targetGroupsPrograms ? targetGroupsPrograms : [],
        columns: q.columns ? q.columns : [],
        show_all_content_with_traffic_in_period: q.show_all_content_with_traffic_in_period,
    }
    return APIQuery
}

// hjälpfunktion för att exekvera programfrågan
const executeQuery = async (context, query) => {
    // uppdatera queryForAPI så det blir enklare att debugga
    context.commit(Mutations.setProgramsQueryForAPI, query)
    try {
        var r = await api.executeProgramsQuery(query)
        // fått ett svar
        // kolla om vi fått några rader
        if (r.programs.length == 0) {
            context.commit(Mutations.setProgramsResults, {
                status: "EMPTY_RESULT",
            })
        } else {
            let levelOneProgramsWithLevelTwoPrograms = r.programs
                .filter(p => p.contentGroupTotal && !p.isTotal)
                .map((p, i) => {
                    for (let i = 0; i < p.measures.length; i++) {
                        p[`measure_${i + 1}`] = p.measures[i]
                    }
                    let levelTwoPrograms = r.programs
                        .filter(lv2p => lv2p.groupKey === p.groupKey && !lv2p.contentGroupTotal)
                        .map(p => {
                            for (let i = 0; i < p.measures.length; i++) {
                                p[`measure_${i + 1}`] = p.measures[i]
                            }
                            return {
                                ...p,
                                chosen: false,
                            }
                        })
                    Helpers.setLevelOneValuesForPrograms(p, levelTwoPrograms, query.columns)
                    return {
                        ...p,
                        levelTwoPrograms: levelTwoPrograms,
                        index: i,
                        chosen: false,
                    }
                })

            let fields = context.getters.getProgramsColumnTypes
                .filter(c => query.columns.includes(c.value))
                .map(c => {
                    return {
                        key: c.value,
                        label: c.text,
                        sortable: true,
                    }
                })
            let fieldsMeasures = []
            for (let i = 0; i < r.measureHeaders.length; i++) {
                fieldsMeasures.push({
                    key: `measure_${i + 1}`,
                    label: `${r.measureHeaders[i].header} ${r.measureHeaders[i].subHeader}`,
                    header: r.measureHeaders[i].header,
                    subHeader: r.measureHeaders[i].subHeader,
                    sortable: true,
                    tdClass: "measureTdClass",
                    thClass: "measurethclass",
                })
            }

            context.commit(Mutations.setProgramsResults, {
                status: "LOADED",
                data: {
                    levelOneProgramsWithLevelTwoPrograms: levelOneProgramsWithLevelTwoPrograms,
                    columns: [...fields, ...fieldsMeasures],
                },
            })
        }
    } catch (error) {
        context.commit(Mutations.setProgramsResults, {
            status: "FAILED",
            data: null,
            error: error,
        })
    }
}

const executeSummaryQuery = async (context, query) => {
    // uppdatera queryForAPI så det blir enklare att debugga
    context.commit(Mutations.setProgramsQueryForAPI, query)
    try {
        var r = await api.executeProgramsQuery(query)
        // fått ett svar
        // kolla om vi fått några rader
        if (r.programs.length == 0) {
            context.commit(Mutations.setProgramsSummaryResults, {
                status: "EMPTY_RESULT",
            })
        } else {
            let levelOneProgramsWithLevelTwoPrograms = r.programs
                .filter(p => p.contentGroupTotal)
                .map((p, i) => {
                    for (let i = 0; i < p.measures.length; i++) {
                        p[`measure_${i + 1}`] = p.measures[i]
                    }
                    let levelTwoPrograms = r.programs
                        .filter(lv2p => lv2p.groupKey === p.groupKey && !lv2p.contentGroupTotal)
                        .map(p => {
                            for (let i = 0; i < p.measures.length; i++) {
                                p[`measure_${i + 1}`] = p.measures[i]
                            }
                            return {
                                ...p,
                                chosen: false,
                            }
                        })
                    Helpers.setLevelOneValuesForPrograms(p, levelTwoPrograms, query.columns)
                    return {
                        ...p,
                        levelTwoPrograms: levelTwoPrograms,
                        index: i,
                        chosen: false,
                    }
                })

            let fields = context.getters.getProgramsColumnTypes
                .filter(c => query.columns.includes(c.value))
                .map(c => {
                    return {
                        key: c.value,
                        label: c.text,
                        sortable: true,
                    }
                })
            let fieldsMeasures = []

            for (let i = 0; i < r.measureHeaders.length; i++) {
                fieldsMeasures.push({
                    key: `measure_${i + 1}`,
                    label: `${r.measureHeaders[i].header} ${r.measureHeaders[i].subHeader}`,
                    header: r.measureHeaders[i].header,
                    subHeader: r.measureHeaders[i].subHeader,
                    sortable: true,
                    tdClass: "measureTdClass",
                    thClass: "measurethclass",
                })
            }
            context.commit(Mutations.setProgramsSummaryResults, {
                status: "LOADED",
                data: {
                    levelOneProgramsWithLevelTwoPrograms: levelOneProgramsWithLevelTwoPrograms,
                    columns: [...fields, ...fieldsMeasures],
                },
            })
        }
    } catch (error) {
        context.commit(Mutations.setProgramsSummaryResults, {
            status: "FAILED",
            data: null,
            error: error,
        })
    }
}

const actions = {
    // hämta alla data som krävs för att visa sidan
    // uppdatera state programInitStatus för att kunna ha spinners etc vid laddning

    [Actions.getProgramsColumnTypes]: async () => {
        return await api.getColumnTypes("PROGRAMS")
    },
    [Actions.getProgramsMeasures]: async () => {
        return await api.getMeasures("PROGRAMS")
    },
    [Actions.getProgramsProviders]: async () => {
        return await api.getProviders(Tabs.ProgramTab)
    },
    [Actions.getProgramsGenres]: async () => {
        return await api.getGenres()
    },

    // denna initialiserar tabellen och frågan, alltså använda då tryck på 'Sök'
    [Actions.executeProgramsQuery]: async (context, showConsumtionPeriodProgram) => {
        context.commit(Mutations.setProgramsResults, {
            status: "LOADING",
            data: null,
        })
        // initiera/resetta selected rows
        context.commit(Mutations.setProgramsSelectedResults, [])
        const query = convertFromQueryToQueryForAPI(context.state.programsQuery, showConsumtionPeriodProgram)
        await executeQuery(context, query)
    },
    //initialiserar csv-export förfrågan för program, använd vid tryck på 'Exportera'
    [Actions.executeProgramsSummaryQuery]: async (context, content_keys) => {
        context.commit(Mutations.setProgramsSummaryResults, {
            status: "LOADING",
            data: null,
        })
        const query = {
            ...context.state.programsQuery,
            content_keys: content_keys.programs,
        }
        const apiQuery = convertFromQueryToQueryForAPI(query, content_keys.showConsumtionPeriodProgram)
        await executeSummaryQuery(context, apiQuery)
    },
    //initialiserar csv-export förfrågan för programsummering, använd vid tryck på 'Exportera'
    [Actions.executeExportProgramsQuery]: async (context, options) => {
        const apiQuery = {
            ...context.state.programsQueryForAPI,
            content_keys: options.programs,
        }
        apiQuery.end_date = options.showConsumtionPeriodProgram ? apiQuery.end_date : apiQuery.start_date
        apiQuery.include_detail_rows = context.state.programsExportQuery.include_detail_rows
        apiQuery.include_header = context.state.programsExportQuery.include_header
        apiQuery.include_summary_row = false

        if (apiQuery.include_header) {
            apiQuery.headeritems = Helpers.getHeaderProgramTab(context, "Program, lista fliken")
        }
        return await api.executeExportProgramsQuery(apiQuery)
    },

    [Actions.executeExportProgramsSummaryQuery]: async (context, options) => {
        const apiQuery = {
            ...context.state.programsQueryForAPI,
            content_keys: options.programs,
        }
        apiQuery.end_date = options.showConsumtionPeriodProgram ? apiQuery.end_date : apiQuery.start_date
        apiQuery.include_detail_rows = context.state.programsExportQuery.include_detail_rows
        apiQuery.include_header = context.state.programsExportQuery.include_header
        apiQuery.include_summary_row = true
        if (apiQuery.include_header) {
            apiQuery.headeritems = Helpers.getHeaderProgramTab(context, "Program, summerings fliken")
        }
        return await api.executeExportProgramsQuery(apiQuery)
    },
}

const getters = {
    getProgramsQuery: state => state.programsQuery,
    getProgramsQueryProviders: state => state.programsQuery.provider_keys,
    getProgramsResults: state => state.programsResults,
    getProgramsSummaryResults: state => state.programsSummaryResults,
    getProgramsSelectedRowsIndex: state => state.programsResults.data.selectedRowsIndex,
    getProgramsSelectedResults: state => state.programsSelectedResults ?? [],
    getProgramsHaveSelectedRow: state => state.programsHaveSelectedRow,
    getProgramsSummary: state => state.programsSummary,
    getProgramsQueryForAPI: state => state.programsQueryForAPI,
    getProgramsExportQuery: state => state.programsExportQuery,
    getTabIndex: state => state.tabIndex,
    getProgramsColumnTypes: ({ programsColumnTypes }) => programsColumnTypes,
    getProgramsProviders: state => state.programsProviders,
    getProgramsInitStatus: ({ programsInitStatus }) => programsInitStatus,
    getProgramsDefaultColumns: state => state.programsDefaultColumns,
    getProgramsAllWithStarSelected: state => state.programsAllWithStarSelected,
}

const mutations = {
    [Mutations.setProgramsQuery]: (state, payload) => (state.programsQuery = payload),
    [Mutations.setProgramsQueryProviders]: (state, payload) => (state.programsQuery.provider_keys = payload),
    [Mutations.setProgramsResults]: (state, payload) => (state.programsResults = payload),
    [Mutations.setProgramsSelectedRowsIndex]: (state, payload) => (state.programsResults.data.selectedRowsIndex = payload),
    [Mutations.setProgramsSelectedResults]: (state, payload) => (state.programsSelectedResults = payload),
    [Mutations.setProgramsHaveSelectedRow]: (state, payload) => (state.programsHaveSelectedRow = payload),
    [Mutations.setProgramsSummaryResults]: (state, payload) => (state.programsSummaryResults = payload),
    [Mutations.setProgramsColumnTypes]: (state, payload) => (state.programsColumnTypes = payload),
    [Mutations.setProgramsInitStatus]: (state, payload) => (state.programsInitStatus = payload),
    [Mutations.setProgramsQueryForAPI]: (state, payload) => (state.programsQueryForAPI = payload),
    [Mutations.setProgramsExportQuery]: (state, payload) => (state.programsExportQuery = payload),
    [Mutations.setProgramsChannels]: (state, payload) => (state.programsChannels = payload),
    [Mutations.setProgramsProviders]: (state, payload) => (state.programsProviders = payload),
    [Mutations.setProgramsStartdate]: (state, payload) => (state.programsQuery.start_date = payload),
    [Mutations.setProgramsEnddate]: (state, payload) => (state.programsQuery.end_date = payload),
    [Mutations.setProgramsPubStartdate]: (state, payload) => (state.programsQuery.pub_start_date = payload),
    [Mutations.setProgramsPubEnddate]: (state, payload) => (state.programsQuery.pub_end_date = payload),
    [Mutations.setProgramsSearchForPubDate]: (state, payload) => (state.programsQuery.searchForPubDate = payload),
    [Mutations.setProgramsGenres]: (state, payload) => (state.programsGenres = payload),
    [Mutations.setProgramsDefaultColumns]: (state, payload) => (state.programsDefaultColumns = payload),
    [Mutations.setProgramsAllWithStarSelected]: (state, payload) => (state.programsAllWithStarSelected = payload),
    [Mutations.setProgramsWeekdays]: (state, payload) => (state.programsWeekdayNumbers = payload),
}

export default {
    state,
    getters,
    mutations,
    actions,
}
