<template>
    <div>
        <b-modal @ok="e => $emit('ok', e)" @cancel="e => $emit('cancel', e)" size="m" :centered="dialogVerticalCenter" :id="dialogId" :title="dialogTitle">
            <div class="form-row">
                {{ dialogMessage }}
            </div>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="outline-secondary" @click="cancel()">
                    Nej
                </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                    Ja
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: "ConfirmDialog",
    props: {
        dialogId: String,
        dialogTitle: {
            type: String,
            default: "Är du säker?",
        },
        dialogMessage: {
            type: String,
            default: "Du har gjort förändringar i dialogen. Är du säker på att du vill stänga den utan att spara?",
        },
        dialogVerticalCenter: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {},
    data() {
        return {}
    },

    computed: {},
    methods: {},
}
</script>

<style scoped></style>
