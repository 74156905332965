import Vue from "vue"
import Vuex from "vuex"

import systemInfo from "./modules/systemInfo"
import auth from "./modules/auth"
import campaigns from "./modules/campaigns"
import channels from "./modules/channels"
import programs from "./modules/programs"

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        systemInfo,
        auth,
        campaigns,
        channels,
        programs,
    },
})
