// exportera ut konstanter som vi använder i resten av programmet för att slippa jobba med strängar.
export const setProgramsQuery = "setProgramsQuery"
export const setProgramsQueryProviders = "setProgramsQueryProviders"
export const setProgramsResults = "setProgramsResults"
export const setProgramsSelectedResults = "setProgramsSelectedResults"
export const setProgramsHaveSelectedRow = "setProgramsHaveSelectedRow"
export const setProgramsSelectedRowsIndex = "setProgramsSelectedRowsIndex"
export const setProgramsSummary = "setProgramsSummary"
export const setProgramsColumnTypes = "setProgramsColumnTypes"
export const setProgramsInitStatus = "setProgramsInitStatus"
export const setProgramsQueryForAPI = "setProgramsQueryForAPI"
export const setProgramsExportQuery = "setProgramsExportQuery"
export const setProgramsChannels = "setProgramsChannels"
export const setProgramsProviders = "setProgramsProviders"
export const setProgramsStartdate = "setProgramsStartdate"
export const setProgramsEnddate = "setProgramsEnddate"
export const setProgramsPubStartdate = "setProgramsPubStartdate"
export const setProgramsPubEnddate = "setProgramsPubEnddate"
export const setProgramsSearchForPubDate = "setProgramsSearchForPubDate"
export const setProgramsGenres = "setProgramsGenres"
export const setProgramsDefaultColumns = "setProgramsDefaultColumns"
export const setProgramsAllWithStarSelected = "setProgramsAllWithStarSelected"
export const setProgramsWeekdays = "setProgramsWeekdays"
export const setProgramsSummaryResults = "setProgramsSummaryResults"
