import axios from "axios"
import { axiosDefault, axiosWAuth } from "./axios"
let config = null

class ApiRequest {
    constructor(args) {
        const { url = "/", method = "post", payload = null, isAuth = false } = args
        this.url = url
        this.method = method
        this.payload = payload
        this.isAuth = isAuth
    }

    run = async () => {
        if (!config) {
            config = await axios.get("./config/runtime.json").then(conf => conf.data)
        }

        if (this.isAuth) {
            return axiosWAuth(config)[this.method](this.url, this.payload && this.payload)
        } else {
            return axiosDefault(config)[this.method](this.url, this.payload && this.payload)
        }
    }
}

export const fetchFormFieldData = async (field, tab) => {
    let url = "/api/v1/" + field
    if (tab) url = url + `?tab=${tab}`
    const request = new ApiRequest({
        url,
        method: "get",
        isAuth: true,
    })
    const response = await request.run()
    return response.data
}

export const getAdvertisers = async payload => {
    const url = "/api/v1/AdSearch/AdvertiserSearch"
    const request = new ApiRequest({
        url,
        isAuth: true,
        payload: payload,
    })
    const response = await request.run()
    return response.data
}
export const getBuyingAgencies = async payload => {
    const url = "/api/v1/AdSearch/BuyingAgencySearch"
    const request = new ApiRequest({
        url,
        isAuth: true,
        payload: payload,
    })
    const response = await request.run()
    return response.data
}

export const getProducts = async payload => {
    const url = "/api/v1/AdSearch/ProductNameSearch"
    const request = new ApiRequest({
        url,
        isAuth: true,
        payload: payload,
    })
    const response = await request.run()
    return response.data
}

export const getFilmCodes = async payload => {
    const url = "/api/v1/AdSearch/FilmCodeSearch"
    const request = new ApiRequest({
        url,
        isAuth: true,
        payload: payload,
    })
    const response = await request.run()
    return response.data
}
/*
olika kolumner tillgängliga för olika vyer
*/
export const getColumnTypes = async view => {
    const url = `/api/v1/ColumnTypes?view=${view}`
    const request = new ApiRequest({
        url,
        isAuth: true,
        method: "get",
    })
    const response = await request.run()
    return response.data
}
export const getMeasures = async view => {
    const url = `/api/v1/Measures?view=${view}`
    const request = new ApiRequest({
        url,
        isAuth: true,
        method: "get",
    })
    const response = await request.run()
    return response.data
}
export const getProviders = async tab => {
    const url = `/api/v1/Provider?tab=${tab}`
    const request = new ApiRequest({
        url,
        isAuth: true,
        method: "get",
    })
    const response = await request.run()
    return response.data
}

export const getGenres = async () => {
    const url = `/api/v1/Genre`
    const request = new ApiRequest({
        url,
        isAuth: true,
        method: "get",
    })
    const response = await request.run()
    return response.data
}

export const executeGenresQuery = async query => {
    const url = `/api/v1/QueryData`
    const request = new ApiRequest({
        url,
        isAuth: true,
        payload: query,
    })
    const response = await request.run()
    return response.data
}

export const executeSiteQuery = async query => {
    const url = `/api/v1/SiteQuery/MainResult`
    const request = new ApiRequest({
        url,
        isAuth: true,
        payload: query,
    })
    const response = await request.run()
    return response.data
}

export const executeProgramsQuery = async query => {
    const url = `/api/v1/ProgramQuery/MainResult`
    const request = new ApiRequest({
        url,
        isAuth: true,
        payload: query,
    })
    const response = await request.run()
    return response.data
}

export const executeCampaignsQuery = async query => {
    const url = `/api/v1/AdQuery/MainResult`
    const request = new ApiRequest({
        url,
        isAuth: true,
        payload: query,
    })
    const response = await request.run()
    return response.data
}

export const getCategories = async () => {
    const url = "/api/v1/Categories"
    const request = new ApiRequest({
        url,
        isAuth: true,
    })
    const response = await request.run()
    return response.data
}

export const executeExportProgramsQuery = async query => {
    const url = `/api/v1/ProgramQuery/Export`
    const request = new ApiRequest({
        url,
        isAuth: true,
        payload: query,
    })
    const response = await request.run()
    return response.data
}

export const executeExportChannelsQuery = async query => {
    const url = `/api/v1/SiteQuery/Export`
    const request = new ApiRequest({
        url,
        isAuth: true,
        payload: query,
    })
    const response = await request.run()
    return response.data
}

export const getSpottypes = async () => {
    const url = `/api/v1/Filmtypes`
    const request = new ApiRequest({
        url,
        isAuth: true,
        method: "get",
    })
    const response = await request.run()
    return response.data
}
