import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import vSelect from "vue-select"
import { store } from "./store"
import { BootstrapVue, IconsPlugin, ToastPlugin } from "bootstrap-vue"
import browserDetect from "vue-browser-detect-plugin"
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core"

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

/* import specific icons */
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons"

/* add icons to the library */
library.add(faMinusCircle)
library.add(faPlusCircle)

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon)

//Styles
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import "vue-date-pick/dist/vueDatePick.css"
import "vue-select/dist/vue-select.css"
import "@/assets/css/main.css"
import "@/assets/css/datepick.css"
import globalConfig from "@/mixins/globalConfig"

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ToastPlugin)
Vue.use(vSelect)
Vue.use(browserDetect)

export const bus = new Vue()

Vue.config.productionTip = false

const getRuntimeConfig = async () => {
    const runtimeConfig = await fetch("./config/runtime.json")
    return runtimeConfig.json()
}

getRuntimeConfig().then(() => {
    Vue.mixin(globalConfig)
})

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount("#app")
