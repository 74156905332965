import axios from "axios"
import { store } from "@/store/index"
import router from "@/router/index"

export function axiosDefault(config) {
    const instance = axios.create({
        baseURL: config.WebApiUrl,
    })

    return instance
}

export function axiosWAuth(config) {
    const instance = axios.create({
        baseURL: config.WebApiUrl,
        withCredentials: true,
    })

    //Add Authorization to request header
    instance.interceptors.request.use(
        config => {
            // Check if the application is in maintenance mode
            if (store.getters.getMaintenanceStatus) {
                // Cancel the request if the application is in maintenance mode
                const cancel = axios.CancelToken.source()
                config.cancelToken = cancel.token
                cancel.cancel("Application is in maintenance mode")
                return Promise.reject(cancel)
            }

            let jwt = store.getters.getAccessToken || localStorage.getItem("accessToken")
            config.headers.common = {
                ...config.headers.common,
                Authorization: "Bearer " + jwt,
            }
            return config
        },
        error => Promise.reject(error)
    )

    //Check responses for 401 response (reach out to refresh token here when we get refresh token)
    instance.interceptors.response.use(
        response => {
            return response
        },
        error => {
            if (error.response.status === 401) {
                return new Promise((resolve, reject) => {
                    store.dispatch("logout")
                    window.location.href = config.WebCaatAuthWebUrl + "?returnUrl=" + config.MyOwnApplicationUrl
                    reject(error)
                })
            } else if (error.response.status === 403 && error.response.data.errorCode === "NON_MMS_USER") {
                store.commit("setMaintenanceStatus", true)
                if (router.history.current.path !== "/maintenance") router.push("/maintenance")
                console.error("Specific 403 error:", error.response.data.errorCode)
            }
            // Generic error handler
            return Promise.reject(error)
        }
    )

    return instance
}
