<template>
    <div>
        <Layout />
    </div>
</template>

<script>
import Layout from "@/components/Layout/Layout.vue"

export default {
    name: "Home",
    components: {
        Layout,
    },
}
</script>
