// exportera ut konstanter som vi använder i resten av programmet för att slippa jobba med strängar.
export const getCampaignsColumnTypes = "getCampaignsColumnTypes"
export const getCampaignsMeasures = "getCampaignsMeasures"
export const getCampaignsBuyingAgencies = "getCampaignsBuyingAgencies"
export const getCampaignsAdvertisers = "getCampaignsAdvertisers"
export const getCampaignsProducts = "getCampaignsProducts"
export const executeExportCampaignsQuery = "executeExportCampaignsQuery"
export const executeCampaignsQuery = "executeCampaignsQuery"
export const executeCampaignsQueryMoreScroll = "executeCampaignsQueryMoreScroll"
export const executeCampaignsQueryMoreExclude = "executeCampaignsQueryMoreExclude"
export const changeSortCampaigns = "changeSortCampaigns"
export const getCampaignsSpottypes = "getCampaignsSpottypes"
export const getCampaignsQuery = "getCampaignsQuery"
