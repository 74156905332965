// exportera ut konstanter som vi använder i resten av programmet för att slippa jobba med strängar.
export const setChannelsQuery = "setChannelsQuery"
export const setChannelsExportQuery = "setChannelsExportQuery"
export const setChannelsQueryProviders = "setChannelsQueryProviders"
export const setChannelsResults = "setChannelsResults"
export const setChannelsSummaryResults = "setChannelsSummaryResults"
export const setChannelsHaveSelectedRow = "setChannelsHaveSelectedRow"
export const setChannelsColumnTypes = "setChannelsColumnTypes"
export const setChannelsInitStatus = "setChannelsInitStatus"
export const setChannelsStartdate = "setChannelsStartdate"
export const setChannelsEnddate = "setChannelsEnddate"
export const setChannelsDefaultColumns = "setChannelsDefaultColumns"
export const setChannelsProviders = "setChannelsProviders"
export const setChannelsSelectedChannelInfo = "setChannelsSelectedChannelInfo"
