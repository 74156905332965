import Cookies from "js-cookie"
import axios from "axios"

const state = {
    config: null,
    loggedIn: false,
    user: null,
    accessToken: null,
    maintenanceStatus: false,
}

const getters = {
    getConfig: state => state.config,
    getLoggedIn: state => state.loggedIn,
    getUser: state => state.user,
    getAccessToken: state => state.accessToken,
    getMaintenanceStatus: state => state.maintenanceStatus,
}

const mutations = {
    setConfig: (state, payload) => (state.config = payload),
    setLoggedIn: (state, payload) => (state.loggedIn = payload),
    setUser: (state, payload) => (state.user = payload),
    setAccessToken: (state, payload) => (state.accessToken = payload),
    setMaintenanceStatus: (state, payload) => (state.maintenanceStatus = payload),
}

const actions = {
    async loadConfig({ commit }) {
        const config = await axios.get("./config/runtime.json").then(conf => conf.data)
        await commit("setConfig", config)
    },
    login({ commit }, payload) {
        const token = payload.token
        const username = payload.authenticationStatus.user.username
        localStorage.setItem("accessToken", token)
        localStorage.setItem("user", username)
        commit("setUser", username)
        commit("setAccessToken", token)
        commit("setLoggedIn", true)
    },
    async logout({ commit, state, dispatch }) {
        await dispatch("loadConfig")
        localStorage.removeItem("accessToken")
        localStorage.removeItem("user")
        Cookies.remove("WebservicesJwt", {
            domain: state.config.AuthCookieDomain,
            path: "/",
            secure: true,
        })
        Cookies.remove("User", { domain: state.config.AuthCookieDomain })
        await commit("setUser", null)
        await commit("setAccessToken", null)
        await commit("setLoggedIn", false)
    },
}

export default {
    state,
    mutations,
    actions,
    getters,
}
