<template>
    <div class="subnavbackground">
        <b-button v-if="config_NewDataUrl" v-b-tooltip.hover.right="'Data efter 2023-12-10'" @click="navigateToNewData" class="ml-1" style="font-size: 0.8rem" size="sm" variant="primary"> Se nyare data </b-button>
        <div class="centered">
            <router-link to="/programs">Program</router-link>
            <router-link to="/campaigns" v-if="config_ShowCampaignTab || config_ExternalCampaignTabLink">Reklam</router-link>
            <router-link to="/channels">Kanaler</router-link>
        </div>
    </div>
</template>

<script>
import globalConfig from "../../../mixins/globalConfig"
export default {
    mixins: [globalConfig],
    name: "SubNav",
    async mounted() {
        await this.loadGlobalConfigFromFile()
    },
    methods: {
        navigateToNewData() {
            window.location.replace(this.config_NewDataUrl)
        },
    },
}
</script>

<style scoped>
a {
    text-transform: uppercase;
    margin: 5px 20px;
    position: relative;
    display: inline-block;
    color: #337ab7;
}
.router-link-exact-active {
    font-weight: bold;
}
.subnavbackground {
    background-color: white;
    border-bottom: #aaa 1px dotted;
    height: 39px;
    padding: 15px 0px 15px 0px;
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.centered {
    position: absolute;
    left: 50%;
    right: 50%;
    padding-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}
</style>
