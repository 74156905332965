import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"

Vue.use(VueRouter)

const checkTabRedirect = (configKey, redirectKey) => async (to, from, next) => {
    const runtimeConfig = await fetch("./config/runtime.json").then(response => response.json())

    if (runtimeConfig[redirectKey]) {
        window.location.href = runtimeConfig[redirectKey]
        return
    }

    if (configKey && !runtimeConfig[configKey]) next(false)
    else next()
}

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "/",
                component: () => import("@/components/Layout/Landing/Landing"),
            },
            {
                path: "/campaigns",
                component: () => import("@/components/Layout/Campaigns/Campaigns"),
                beforeEnter: checkTabRedirect("ShowCampaignTab", "ExternalCampaignTabLink"),
            },
            {
                path: "/channels",
                component: () => import("@/components/Layout/Channels/Channels"),
                beforeEnter: checkTabRedirect(null, "ExternalChannelTabLink"),
            },
            {
                path: "/programs",
                component: () => import("@/components/Layout/Programs/Programs"),
                beforeEnter: checkTabRedirect(null, "ExternalProgramTabLink"),
            },
        ],
    },
    {
        path: "/maintenance",
        name: "Maintenance",
        component: () => import("@/views/Maintenance.vue"),
    },
    {
        path: "*",
        redirect: "/",
    },
]

const router = new VueRouter({
    mode: "history",
    routes,
})

export default router
