<template>
    <div id="app">
        <ConfirmDialog
            :dialogId="confirmDialogId"
            :dialogVerticalCenter="true"
            dialogTitle="Ny version finns"
            dialogMessage="Du måste ladda om sidan för att allt ska fungera korrekt med den uppdaterade versionen. Vill du göra det nu?"
            @ok="updateVersion"
            @hidden="onDialogHidden"
        />
        <router-view />
    </div>
</template>

<script>
import ConfirmDialog from "@/components/Shared/FormElement/Dialogs/ConfirmDialog"
import globalConfig from "./mixins/globalConfig"
import * as Helpers from "@/helpers/index.js"

export default {
    mixins: [globalConfig],
    components: {
        ConfirmDialog,
    },
    data() {
        return {
            confirmDialogId: "version-update-modal-confirm",
            canShowUpdateDialog: true,
            dialogCooldownPeriod: 300000, // 5 minutes in milliseconds
            lastDialogShownAt: 0,
        }
    },
    async mounted() {
        if (this.config_CurrentClientVersion !== "local-dev") {
            await this.checkVersion()
            this.versionIntervalId = setInterval(this.checkVersion, this.dialogCooldownPeriod);
        }
        this.init()
    },
    beforeDestroy() {
        clearInterval(this.versionIntervalId)
    },
    methods: {
        async checkVersion() {
            try {
                await this.loadGlobalConfigFromFile()
                let variablesSetByClientVersion = await localStorage.getItem("VariablesSetByClientVersion")
                if (!variablesSetByClientVersion) {
                    localStorage.setItem("VariablesSetByClientVersion", this.config_CurrentClientVersion)
                } else if (this.config_MiniumClientVersion && Helpers.isVersionLessThan(variablesSetByClientVersion, this.config_MiniumClientVersion)) {
                    const timeSinceLastShown = Date.now() - this.lastDialogShownAt
                    if (this.canShowUpdateDialog && timeSinceLastShown > this.dialogCooldownPeriod) {
                        this.$bvModal.show(this.confirmDialogId)
                        this.lastDialogShownAt = Date.now()
                    }
                }
            } catch (error) {
                console.error("Error in checkVersion method: ", error)
            }
        },
        async updateVersion() {
            const token = await localStorage.getItem("accessToken")
            const user = await localStorage.getItem("user")
            this.resetLocalStorage()
            localStorage.setItem("VariablesSetByClientVersion", this.config_CurrentClientVersion)
            this.setUserAndToken(token, user)
            window.location.reload()
        },
        async init() {
            const token = await localStorage.getItem("accessToken")
            const user = await localStorage.getItem("user")
            this.setUserAndToken(token, user)
            // this.checkUserStatus();
        },
        onDialogHidden() {
            this.canShowUpdateDialog = false
            setTimeout(() => {
                this.canShowUpdateDialog = true
            }, this.dialogCooldownPeriod)
        },
        resetLocalStorage() {
            localStorage.clear()
        },
        setUserAndToken(token, user) {
            localStorage.setItem("accessToken", token)
            localStorage.setItem("user", user)
            this.$store.commit("setUser", user)
            this.$store.commit("setAccessToken", token)
            this.$store.commit("setLoggedIn", true)
        },
    },
}
</script>

<style>
#app {
    font-family: Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;

    height: 100vh;
    display: flex;
    flex-direction: column;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
