export const targetObjToString = (obj = 0) => {
    const measure = obj.measure ? obj.measure : ""
    const group = obj.group ? obj.group : ""
    const age_from = obj.age_from ? obj.age_from : ""
    const age_to = obj.age_to ? obj.age_to : ""
    const consolidation = obj.consolidation ? obj.consolidation : ""
    const targetString = measure + ", " + group + age_from + "-" + age_to + ", " + consolidation

    return targetString
}

export const appendTargetCols = (query, data) => {
    return data.map(item => {
        query.target_group.map(target => {
            item[targetObjToString(target)] = 0
        })
        return item
    })
}
export function dateToyymmddFormat(item) {
    if (item == null) return ""

    let date = new Date(item)
    return date.toLocaleDateString("sv-SE", {})
}

export function dateToTimeStamp(item) {
    if (item == null) return null
    let date = new Date(item)
    return date.toLocaleTimeString("sv-SE", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
    })
}
export function download(content, filename, contentType) {
    if (!contentType) contentType = "application/octet-stream"
    let a = document.createElement("a")
    content = "\ufeff" + content
    let blob = new Blob([content], { type: contentType })
    a.href = window.URL.createObjectURL(blob)
    a.download = filename
    a.click()
    window.URL.revokeObjectURL(a.href) //Release the blob object
}
function overlapping(a, b) {
    const getMinutes = s => {
        const p = s.split(":").map(Number)
        return p[0] * 60 + p[1]
    }
    return getMinutes(a.end) > getMinutes(b.start) && getMinutes(b.end) > getMinutes(a.start)
}
export function isOverlapping(arr) {
    let i, j
    for (i = 0; i < arr.length - 1; i++) {
        for (j = i + 1; j < arr.length; j++) {
            let previousTime = arr[i].daypart.split("-")
            let nextTime = arr[j].daypart.split("-")
            if (overlapping({ start: previousTime[0], end: previousTime[1] }, { start: nextTime[0], end: nextTime[1] })) {
                return true
            }
        }
    }
    return false
}
export function sortObject(obj) {
    return Object.keys(obj)
        .sort()
        .reduce(function(result, key) {
            result[key] = obj[key]
            return result
        }, {})
}
export function unselectFilteredOutChannels(selectedChannels, newChannels) {
    let newChannelsFiltered = { ...newChannels }
    let selectedChannelHousesKeys = Object.keys(selectedChannels).sort()
    for (var name in newChannelsFiltered) {
        if (!selectedChannelHousesKeys.includes(name)) {
            delete newChannelsFiltered[name]
        }
    }
    Object.values(sortObject(newChannelsFiltered)).reduce((result, channels, indexChannelHouse) => {
        let selectedChannelsInChannelHouse = selectedChannels[selectedChannelHousesKeys[indexChannelHouse]]
        let channelsValues = channels.map(c => c.item)
        for (let i = 0; i < selectedChannelsInChannelHouse.length; i++) {
            if (!channelsValues.includes(selectedChannelsInChannelHouse[i])) {
                let index = selectedChannelsInChannelHouse.indexOf(selectedChannelsInChannelHouse[i])
                selectedChannelsInChannelHouse.splice(index, 1)
            }
        }
    }, {})
}
export function compareNumbers(a, b) {
    return a - b
}
export const objectArraysEqual = (o1a, o2a) => {
    let objectArraysEqual = o1a.length !== o2a.length ? false : true
    if (objectArraysEqual) {
        for (let i = 0; i < o1a.length; i++) {
            objectArraysEqual = Object.keys(o1a[i]).length === Object.keys(o2a[i]).length && Object.keys(o1a[i]).every(p => o1a[i][p] === o2a[i][p])
        }
    }
    return objectArraysEqual
}
export function arraysEqual(a, b) {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false
    a.sort()
    b.sort()
    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false
    }
    return true
}
export function isDateNotBetweenTwoDates(isThisDateBetween, datefrom, dateto) {
    datefrom.setHours(0, 0, 0)
    dateto.setHours(0, 0, 0)
    isThisDateBetween.setHours(0, 0, 0)
    if (isThisDateBetween.getTime() <= dateto.getTime() && isThisDateBetween.getTime() >= datefrom.getTime()) return false
    return true
}
export function scrollToBottom() {
    setTimeout(function() {
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight)
    }, 10)
}
