export const transformToChannelsFromAPIResponse = channels => ({
    config: {
        tabs: {
            ...channels.reduce(
                (prev, cur) => ({
                    ...prev,
                    ...{
                        [cur.group]: [
                            ...(prev[cur.group] ?? []),
                            {
                                name: cur.channel_name,
                                item: cur.orig_code,
                            },
                        ],
                    },
                }),
                {}
            ),
        },
    },
})
export const transformToGenresFromAPIResponse = genres => ({
    config: {
        genres: [...genres],
    },
})

export const uniqueElementsBy = (arr, fn) =>
    arr.reduce((acc, v) => {
        if (!acc.some(x => fn(v, x))) acc.push(v)
        return acc
    }, [])
/*

Store helpers


*/
export const createMeasureName = measure => {
    return `${measure.measure.display_name}\n${measure.group}${measure.age_from}-${measure.age_to}]`
}
export function setLevelOneValuesForPrograms(levelOne, levelTwo, columns) {
    levelTwo.sort((a, b) => (a.providerName > b.providerName ? 1 : b.providerName > a.providerName ? -1 : 0))
    //styling för siter och flytt av data från och till lvl2/lvl1
    if (levelTwo.length === 1) {
        if (levelTwo[0].providerGroup == "Sites") {
            levelTwo[0].providerNameVhtml = `<span style="color: #337ab7; font-weight: bold;">${levelTwo[0].providerName}</span>`
        }
    } else {
        //för varje lvl2 hitta providerName/providerGroup-par
        let providerGroupsNamePairs = levelTwo.reduce((all, p) => {
            if (p.providerGroup !== "Sites") {
                let providerNames = []
                if (all[p.providerGroup]) providerNames = all[p.providerGroup]
                if (!providerNames.includes(p.providerName)) {
                    providerNames.push(p.providerName)
                    all[p.providerGroup] = providerNames
                }
            } else all[p.providerGroup] = p.providerName
            return all
        }, {})
        if (providerGroupsNamePairs?.Sites != undefined) {
            providerGroupsNamePairs.Sites = `<span style="color: #337ab7; font-weight: bold;">${providerGroupsNamePairs.Sites}</span>`
        }
        let providerNameParent = []
        for (const property in providerGroupsNamePairs) {
            let isArray = Array.isArray(providerGroupsNamePairs[property])
            if (isArray) {
                providerNameParent = providerNameParent.concat(providerGroupsNamePairs[property])
                if (providerGroupsNamePairs.Sites) providerNameParent.push(providerGroupsNamePairs.Sites)
            }
        }
        providerNameParent.sort(function(a, b) {
            //remove html for sorting
            a = a.replace(/<[^>]*>?/gm, "")
            b = b.replace(/<[^>]*>?/gm, "")
            if (a < b) {
                return -1
            }
            if (b > a) {
                return 1
            }
            return 0
        })
        levelOne.providerNameArray = []
        providerNameParent = providerNameParent.filter(p => p)
        if (providerGroupsNamePairs.Sites != undefined) levelOne.providerNameVhtml = providerNameParent.join(", ")
        levelOne.providerName = providerNameParent.map(p => p.replace(/<[^>]*>?/gm, "")).join(", ")
        for (const l2 of levelTwo) {
            if (l2.providerGroup == "Sites") {
                l2.providerNameVhtml = `<span style="color: #337ab7; font-weight: bold;">${l2.providerName}</span>`
            }
        }
    }
    for (let i = 0; i < columns.length; i++) {
        //för varje vald kolumn (mått undantaget)
        let columnValues = levelTwo.map(obj => obj[columns[i]]).filter(item => item.trim())
        if (new Set(columnValues).size === 1) {
            if (!levelOne[columns[i]]) levelOne[columns[i]] = columnValues[0]
        }
    }
}
export function setLevelOneValuesForChannels(levelTwo) {
    levelTwo.sort((a, b) => (a.providerName > b.providerName ? 1 : b.providerName > a.providerName ? -1 : 0))
    //styling för siter och flytt av data från och till lvl2/lvl1
    for (const l2 of levelTwo) {
        if (l2.category.toLowerCase() == "site") {
            l2.providerName = `<span style="color: #337ab7; font-weight: bold;">${l2.providerName}</span>`
        }
    }
}
export function convertToRowkeysArraySite(sitearray) {
    let rowKeysArray = []
    for (let ic = 0; ic < sitearray.length; ic++) {
        if (sitearray[ic].chosen) {
            let rowKeysObject = {}
            rowKeysObject.date = sitearray[ic].date
            rowKeysObject.providerKey = sitearray[ic].providerKey
            rowKeysArray.push(rowKeysObject)
        }
    }
    return rowKeysArray
}
export function getHeaderProgramTab(context, tab) {
    let today = new Date()
    let channelNames = context.state.programsProviders.filter(p => p.provider_type == "CHANNEL" && p.exists_content)
    let siteNames = context.state.programsProviders.filter(p => p.provider_type == "SITE" && p.exists_content)
    let chosenChannels = channelNames
        .filter(p => context.state.programsQueryForAPI.provider_keys.includes(p.provider_key))
        .map(p => p.provider_name)
        .join(", ")
    let chosenSites = siteNames
        .filter(p => context.state.programsQueryForAPI.provider_keys.includes(p.provider_key))
        .map(p => p.provider_name)
        .join(", ")
    let chosenGenres = context.state.programsQueryForAPI.genres.genres ? context.state.programsQueryForAPI.genres.genres : []
    chosenGenres = chosenGenres
        .map(function(genre) {
            let mappedGenre = genre.genre1
            if (genre.genre2 && genre.genre2 != "-") mappedGenre += `: ${genre.genre2}`
            if (genre.genre3 && genre.genre3 != "-") mappedGenre += `: ${genre.genre3}`
            return mappedGenre
        })
        .join(" | ")
    return [
        ["Analysdatum", `${today.toISOString().split("T")[0]} ${today.getHours()}:${today.getMinutes()}`],
        ["Verktyg", context.getters.getApplicationHeader, tab],
        ["Version klient, API", context.getters.getClientVersion, context.getters.getApiVersion],
        [],
        ["Konsumtionsperiod", context.state.programsQueryForAPI.start_date, context.state.programsQueryForAPI.end_date],
        context.state.programsQueryForAPI.showPubDate && ["Publiceringsdatum", context.state.programsQueryForAPI.pub_start_date, context.state.programsQueryForAPI.pub_end_date],
        context.state.programsQueryForAPI.search_text && ["Sökord", context.state.programsQueryForAPI.search_text],
        context.state.programsQueryForAPI.provider_keys.length > 0 && ["Kanaler", chosenChannels, chosenSites],
        chosenGenres && ["Genre", chosenGenres],
        ["Visa tomma svar:", context.state.programsQueryForAPI.show_all_content_with_traffic_in_period ? "ja" : "nej"],
    ].filter(Boolean)
}
export function getHeaderChannelTab(context, tab) {
    let today = new Date()
    let channelNames = context.state.channelsProviders.filter(p => p.provider_type == "CHANNEL" && p.exists_content)
    let siteNames = context.state.channelsProviders.filter(p => p.provider_type == "SITE" && p.exists_content)
    let chosenChannels = channelNames
        .filter(p => context.state.channelsExportQuery.provider_keys.includes(p.provider_key))
        .map(p => p.provider_name)
        .join(", ")
    let chosenSites = siteNames
        .filter(p => context.state.channelsExportQuery.provider_keys.includes(p.provider_key))
        .map(p => p.provider_name)
        .join(", ")
    return [
        ["Analysdatum", `${today.toISOString().split("T")[0]} ${today.getHours()}:${today.getMinutes()}`],
        ["Verktyg", context.getters.getApplicationHeader, tab],
        ["Version klient, API", context.getters.getClientVersion, context.getters.getApiVersion],
        [],
        ["Konsumtionsperiod", context.state.channelsExportQuery.start_date, context.state.channelsExportQuery.end_date],
        context.state.channelsExportQuery.provider_keys.length > 0 && ["Kanaler", chosenChannels, chosenSites],
    ].filter(Boolean)
}

// checks if a version in format ex. 1.44.5345 is less than the required version
export function isVersionLessThan(version, requiredVersion) {
    // if either version or requiredVersion is null or undefined, return false
    if (!version || !requiredVersion) {
        return false
    }

    const versionDots = (version.match(/\./g) || []).length
    const requiredVersionDots = (requiredVersion.match(/\./g) || []).length

    if (versionDots === requiredVersionDots) {
        // remove all non-numeric characters except the decimal point from the strings
        let v1parts = version.split(".").map(Number)
        let v2parts = requiredVersion.split(".").map(Number)

        // pad the shorter array with zeros
        while (v1parts.length < v2parts.length) v1parts.push(0)
        while (v2parts.length < v1parts.length) v2parts.push(0)

        for (let i = 0; i < v1parts.length; ++i) {
            if (v1parts[i] < v2parts[i]) {
                return true
            } else if (v1parts[i] > v2parts[i]) {
                return false
            }
        }

        // the versions are identical
        return false
    } else {
        // fallback, remove all non-numeric characters and the decimal point from the strings
        version = parseFloat(version.replace(/[^0-9]/g, ""))
        requiredVersion = parseFloat(requiredVersion.replace(/[^0-9]/g, ""))

        return version < requiredVersion
    }
}