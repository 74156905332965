const globalConfig = {
    data() {
        return {
            config_CurrentClientVersion: "",
            config_BackgroundColor: "",
            config_ShowConsumtionPeriodProgram: "",
            config_ShowCampaignTab: "",
            config_ShowModelSelection: "",
            config_EnableAgeChannel4Reach: "",
            config_EnableAgeChannel4Time: "",
            config_EnableAgeChannel4Rating: "",
            config_EnableAgeProgram4Reach: "",
            config_EnableAgeProgram4Time: "",
            config_EnableAgeLimitedChannel4Reach: "",
            config_EnableAgeLimitedProgram4Reach: "",
            config_EnableReachTimeProgram: "",
            config_EnableReachTimeChannel: "",
            config_EnableSexProgram: "",
            config_EnableWeekAggChannel: "",
            config_ShowSummaryProgram: "",
            config_ShowSummaryChannel: "",
            config_LinkTvAnalys: "",
            config_LinkOnlineAnalys: "",
            config_LinkDataPortal: "",
            config_MiniumClientVersion: "",
            config_ApplicationHeading: "",
            config_ApplicationSubHeading: "",
            config_NumberOfTargetGroupsChannel: "",
            config_NumberOfTargetGroupsProgram: "",
            config_ExternalCampaignTabLink: "",
            config_ExternalProgramTabLink: "",
            config_ExternalChannelTabLink: "",
            config_NewDataUrl: "",
            config_MyOwnApplicationUrl: "",
            config_WebCaatAuthWebUrl: "",
            config_TotalFrontendv1Url: "",
            config_AuthCookieDomain: ""
        }
    },
    methods: {
        async loadGlobalConfigFromFile() {
            try {
                await fetch("./config/runtime.json")
                    .then(response => {
                        return response.json()
                    })
                    .then(data => {
                        this.config_CurrentClientVersion = data.CurrentClientVersion
                        this.config_BackgroundColor = data.BackgroundColor
                        this.config_ShowConsumtionPeriodProgram = data.ShowConsumtionPeriodProgram
                        this.config_ShowCampaignTab = data.ShowCampaignTab
                        this.config_ShowModelSelection = data.ShowModelSelection
                        this.config_EnableAgeChannel4Reach = data.EnableAgeChannel4Reach
                        this.config_EnableAgeChannel4Time = data.EnableAgeChannel4Time
                        this.config_EnableAgeChannel4Rating = data.EnableAgeChannel4Rating
                        this.config_EnableAgeProgram4Reach = data.EnableAgeProgram4Reach
                        this.config_EnableAgeProgram4Time = data.EnableAgeProgram4Time
                        this.config_EnableAgeLimitedChannel4Reach = data.EnableAgeLimitedChannel4Reach
                        this.config_EnableAgeLimitedProgram4Reach = data.EnableAgeLimitedProgram4Reach
                        this.config_EnableReachTimeProgram = data.EnableReachTimeProgram
                        this.config_EnableReachTimeChannel = data.EnableReachTimeChannel
                        this.config_EnableSexProgram = data.EnableSexProgram
                        this.config_EnableWeekAggChannel = data.EnableWeekAggChannel
                        this.config_ShowSummaryProgram = data.ShowSummaryProgram
                        this.config_ShowSummaryChannel = data.ShowSummaryChannel
                        this.config_LinkTvAnalys = data.LinkTvAnalys
                        this.config_LinkOnlineAnalys = data.LinkOnlineAnalys
                        this.config_LinkDataPortal = data.LinkDataPortal
                        this.config_MiniumClientVersion = data.MiniumClientVersion
                        this.config_ApplicationHeading = data.ApplicationHeading
                        this.config_ApplicationSubHeading = data.ApplicationSubHeading
                        this.config_NumberOfTargetGroupsChannel = data.NumberOfTargetGroupsChannel
                        this.config_NumberOfTargetGroupsProgram = data.NumberOfTargetGroupsProgram
                        this.config_ExternalCampaignTabLink = data.ExternalCampaignTabLink
                        this.config_ExternalProgramTabLink = data.ExternalProgramTabLink
                        this.config_ExternalChannelTabLink = data.ExternalChannelTabLink
                        this.config_NewDataUrl = data.NewDataUrl
                        this.config_MyOwnApplicationUrl = data.MyOwnApplicationUrl
                        this.config_WebCaatAuthWebUrl = data.WebCaatAuthWebUrl
                        this.config_TotalFrontendv1Url = data.TotalFrontendv1Url
                        this.config_AuthCookieDomain = data.AuthCookieDomain
                    })
                    .catch(error => {
                        console.error(error)
                    })
            } catch (error) {
                console.error(error)
            }
        },
    },
}

export default globalConfig
