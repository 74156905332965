// exportera ut konstanter som vi använder i resten av programmet för att slippa jobba med strängar.
export const setCampaignsExcludedCampaignIDs = "setCampaignsExcludedCampaignIDs"
export const setResetExcludedCampaignIDs = "setResetExcludedCampaignIDs"
export const setCampaignsQuery = "setCampaignsQuery"
export const setCampaignsResults = "setCampaignsResults"
export const setCampaignsSelectedResults = "setCampaignsSelectedResults"
export const setCampaignsSelectedRowsIndex = "setCampaignsSelectedRowsIndex"
export const setCampaignsSummary = "setCampaignsSummary"
export const setCampaignsColumnTypes = "setCampaignsColumnTypes"
export const setCampaignsInitStatus = "setCampaignsInitStatus"
export const setCampaignsQueryForAPI = "setCampaignsQueryForAPI"
export const setCampaignsChannels = "setCampaignsChannels"
export const setCampaignsStartdate = "setCampaignsStartdate"
export const setCampaignsEnddate = "setCampaignsEnddate"
export const setCampaignsSpottypes = "setCampaignsSpottypes"
export const setCampaignsDefaultColumns = "setCampaignsDefaultColumns"
export const setCampaignsAllWithStarSelected = "setCampaignsAllWithStarSelected"
export const setCampaignsSumVisible = "setCampaignsSumVisible"
