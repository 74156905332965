import * as Mutations from "./mutation-types"
import * as Actions from "./action-types"
import * as api from "@/api/api"

import * as Helpers from "@/helpers"
const defaultCampaignsQuery = {
    start_date: "",
    end_date: "",
    buyingAgencies: null,
    advertisers: null,
    products: null,
    film_code: [],
}

const state = {
    campaignsInitStatus: "NOT_LOADED",
    campaignsQuery: {
        ...defaultCampaignsQuery,
    },
    campaignsQueryForAPI: {},
    campaignsResults: {
        status: "NOT_LOADED",
    },
    campaignsSelectedResults: [],
    campaignsSummary: [],
    campaignsColumnTypes: [],
    campaignsDefaultColumns: [],
    campaignsAllWithStarSelected: true,
    campaignsSumVisible: false,
    campaignsExcludedCampaignIDs: [],
}

const convertFromQueryToQueryForAPI = (
    q,
    key_column,
    options = {
        sortBy: null,
        sort_direction: "ASC",
        rows_per_page: 10000000,
    }
) => {
    let channels = JSON.parse(localStorage.getItem("savedChannels")) ? JSON.parse(localStorage.getItem("savedChannels")) : {}
    channels = channels["getCampaignsQuery"]

    let filmcodes = JSON.parse(localStorage.getItem("savedFilmCodes")) ? JSON.parse(localStorage.getItem("savedFilmCodes")) : {}
    filmcodes = filmcodes["getCampaignsQuery"]

    // transformera interna queryObjektet till ett som passar vår API
    const defaultCampaignsQueryForAPI = {
        start_date: q.start_date,
        end_date: q.end_date,
        rows_per_page: options.rows_per_page,
        film_codes: filmcodes,
        channel_codes: channels ? Object.keys(channels).reduce((prev, key) => [...prev, ...channels[key]], []) : [],
    }

    const defaultMeasure = {
        min_viewing_time: 0,
    }
    const defaultColumn = {}

    const storedTargets = localStorage.getItem("targetGroups") ? JSON.parse(localStorage.getItem("targetGroups")) : {}
    let targetGroupsCampaigns = storedTargets["getCampaignsQuery"]

    const APIQuery = {
        ...defaultCampaignsQueryForAPI,
        start_date: q.start_date ?? null,
        end_date: q.end_date ?? null,
        buyingAgency: q.buyingAgencies,
        advertiser: q.advertisers,
        product: q.products,
        measures: targetGroupsCampaigns
            ? targetGroupsCampaigns.map(measure => ({
                  ...defaultMeasure,
                  target_group: {
                      group: measure.group,
                      age_from: measure.age_from,
                      age_to: measure.age_to,
                  },
                  display_name: Helpers.createMeasureName(measure),
                  column_type: measure.measure.column_type,
              }))
            : [],
        columns: q.columns
            ? q.columns.map(column => ({
                  ...defaultColumn,
                  ...column,
              }))
            : [],
    }

    return APIQuery
}

const executeQuery = async (context, query) => {
    // uppdatera queryForAPI så det blir enklare att debugga
    context.commit(Mutations.setCampaignsQueryForAPI, query)

    // uppdater loadingMoreRows attribut
    context.commit(Mutations.setCampaignsResults, {
        ...state.campaignsResults,
        loadingMoreRows: true,
    })

    try {
        var r = await api.executeCampaignsQuery(query)
        // fått ett svar
        // kolla om vi fått några rader
        r.ads.push("resultat reklam")
        if (r.ads.length == 0) {
            context.commit(Mutations.setCampaignsResults, {
                status: "EMPTY_RESULT",
            })
        } else {
            context.commit(Mutations.setCampaignsResults, {
                status: "LOADED",
            })
        }
    } catch (error) {
        context.commit(Mutations.setCampaignsResults, {
            status: "FAILED",
            data: null,
            error: error,
        })
    }
}

const actions = {
    // hämta alla data som krävs för att visa sidan
    // uppdatera state programInitStatus för att kunna ha spinners etc vid laddning

    [Actions.getCampaignsColumnTypes]: async () => {
        return await api.getColumnTypes("ADS")
    },
    [Actions.getCampaignsMeasures]: async () => {
        return await api.getMeasures("ADS")
    },
    [Actions.getCampaignsBuyingAgencies]: async () => {
        let dateQuery = {
            start_date: state.campaignsQuery.start_date,
            end_date: state.campaignsQuery.end_date,
        }
        return await api.getBuyingAgencies(dateQuery)
    },
    [Actions.getCampaignsAdvertisers]: async () => {
        let dateQuery = {
            start_date: state.campaignsQuery.start_date,
            end_date: state.campaignsQuery.end_date,
        }

        return await api.getAdvertisers(dateQuery)
    },
    [Actions.getCampaignsProducts]: async () => {
        let dateQuery = {
            start_date: state.campaignsQuery.start_date,
            end_date: state.campaignsQuery.end_date,
        }

        return await api.getProducts(dateQuery)
    },
    // denna initialiserar tabellen och frågan, alltså använda då tryck på 'Sök'
    [Actions.executeCampaignsQuery]: async context => {
        context.commit(Mutations.setCampaignsResults, {
            status: "LOADING",
            data: null,
        })

        // initiera/resetta selected rows
        context.commit(Mutations.setCampaignsSelectedResults, [])
        let keycolumn = null

        // konvertera till fråga för API
        const columnsWithoutKey = context.state.campaignsColumnTypes.filter(col => !col.key).map(x => x.display_name)
        context.state.campaignsQuery.columns.sort(function(a, b) {
            return columnsWithoutKey.indexOf(a.display_name) - columnsWithoutKey.indexOf(b.display_name)
        })
        const query = convertFromQueryToQueryForAPI(context.state.campaignsQuery, keycolumn)
        await executeQuery(context, query)
    },
    [Actions.executeExportCampaignsQuery]: async context => {
        const keycolumn = context.state.campaignsColumnTypes.filter(col => col.key)[0]
        const query = convertFromQueryToQueryForAPI(context.state.campaignsQuery, keycolumn)
        query.sort_by = context.state.campaignsQueryForAPI.sort_by
        query.sort_direction = context.state.campaignsQueryForAPI.sort_direction
        query.excludedCampaignIDs = context.state.campaignsExcludedCampaignIDs
        query.rows_per_page = context.state.campaignsResults.totalRows
        // inte implementerad än
        // return await api.executeExportCampaignQuery(query)
        return
    },
    [Actions.executeCampaignsQueryMoreScroll]: context => {
        // uppdater loadingMoreRows attribut
        context.commit(Mutations.setCampaignsResults, {
            ...state.campaignsResults,
            loadingMoreRows: true,
        })
        try {
            // om vi redan har data i resultatet, lägg på rader
            let tableRows = context.state.campaignsResults.data.tableRows + 1000
            setTimeout(
                () =>
                    context.commit(Mutations.setCampaignsResults, {
                        ...context.state.campaignsResults,
                        data: {
                            ...context.state.campaignsResults.data,
                            tableRows: tableRows,
                        },
                        loadingMoreRows: false,
                    }),
                10
            )
        } catch (error) {
            context.commit(Mutations.setCampaignsResults, {
                status: "FAILED",
                data: null,
                error: error,
            })
        }
    },
    [Actions.executeCampaignsQueryMoreExclude]: async (context, rowsWithoutExcludedRows) => {
        context.commit(Mutations.setCampaignsResults, {
            ...context.state.campaignsResults,
            data: {
                ...context.state.campaignsResults.data,
                selectedRowsIndex: [],
                rows: rowsWithoutExcludedRows,
            },
            loadingMoreRows: false,
            totalRows: rowsWithoutExcludedRows.length,
        })
    },
    [Actions.changeSortCampaigns]: async (context, payload) => {
        context.commit(Mutations.setCampaignsResults, {
            ...state.campaignsResults,
            loadingMoreRows: true,
        })
        let tableRows = [...context.getters.getCampaignsResults.data.rows]
        let isNumeric = /^[0-9. ]+$/.test(tableRows[0][payload.sortBy])
        let isPercentage = /[%]$/.test(tableRows[0][payload.sortBy])
        if (isNumeric) {
            if (payload.sortDesc)
                tableRows.sort((a, b) => {
                    if (typeof a[payload.sortBy] === "string") a[payload.sortBy] = parseFloat(a[payload.sortBy].replace(/\s/g, ""))
                    if (typeof b[payload.sortBy] === "string") b[payload.sortBy] = parseFloat(b[payload.sortBy].replace(/\s/g, ""))
                    return b[payload.sortBy] - a[payload.sortBy]
                })
            else {
                tableRows.sort((a, b) => {
                    if (typeof a[payload.sortBy] === "string") a[payload.sortBy] = parseFloat(a[payload.sortBy].replace(/\s/g, ""))
                    if (typeof b[payload.sortBy] === "string") b[payload.sortBy] = parseFloat(b[payload.sortBy].replace(/\s/g, ""))
                    return a[payload.sortBy] - b[payload.sortBy]
                })
            }
        } else if (isPercentage) {
            if (payload.sortDesc) {
                tableRows.sort((a, b) => {
                    a = parseFloat(a[payload.sortBy].replace(/,/g, ".").replace(/%/g, ""))
                    b = parseFloat(b[payload.sortBy].replace(/,/g, ".").replace(/%/g, ""))
                    if (a < b) {
                        return 1
                    }
                    if (a > b) {
                        return -1
                    }
                    return 0
                })
            } else {
                tableRows.sort((a, b) => {
                    a = parseFloat(a[payload.sortBy].replace(/,/g, ".").replace(/%/g, ""))
                    b = parseFloat(b[payload.sortBy].replace(/,/g, ".").replace(/%/g, ""))
                    if (a < b) {
                        return -1
                    }
                    if (a > b) {
                        return 1
                    }
                    return 0
                })
            }
        } else {
            if (payload.sortDesc) {
                tableRows.sort((a, b) => {
                    a = a[payload.sortBy]
                    b = b[payload.sortBy]
                    if (a < b || a == null) {
                        return 1
                    }
                    if (a > b || b == null) {
                        return -1
                    }
                    return 0
                })
            } else {
                tableRows.sort((a, b) => {
                    a = a[payload.sortBy]
                    b = b[payload.sortBy]
                    if (a < b || a == null) {
                        return -1
                    }
                    if (a > b || b == null) {
                        return 1
                    }
                    return 0
                })
            }
        }
        let summaryArray = context.getters.getCampaignsResults.data.summary.array.map(obj => {
            return { ...obj, visible: false }
        })
        context.commit(Mutations.setCampaignsResults, {
            ...state.campaignsResults,
            data: {
                ...context.state.campaignsResults.data,
                rows: tableRows,
                summary: {
                    ...context.state.campaignsResults.data.summary,
                    array: summaryArray,
                },
            },
            loadingMoreRows: false,
        })

        const sortDir = payload.sortDesc === true ? "DESC" : "ASC"
        // skapa kolumnobjekt från data-objektet
        const column = context.getters.getCampaignsResults.data.columns.filter(c => c.key == payload.sortBy)[0]
        const sortByColumn = (({ column_type, key }) => ({
            column_type,
            display_name: key,
        }))(column)
        context.commit(Mutations.setCampaignsQueryForAPI, {
            ...context.getters.getCampaignsQueryForAPI,
            sort_by: sortByColumn,
            sort_direction: sortDir,
        })
    },

    [Actions.getCampaignsSpottypes]: async () => {
        return await api.getSpottypes()
    },
}

const getters = {
    getCampaignsExcludedCampaignIDs: state => state.campaignsExcludedCampaignIDs,
    getCampaignsQuery: state => state.campaignsQuery,
    getCampaignsResults: state => state.campaignsResults,
    getCampaignsSelectedRowsIndex: state => state.campaignsResults.data.selectedRowsIndex,
    getCampaignsSelectedResults: state => state.campaignsSelectedResults ?? [],
    getCampaignsSummary: state => state.campaignsSummary,
    getCampaignsQueryForAPI: state => state.campaignsQueryForAPI,
    getCampaignsColumnTypes: ({ campaignsColumnTypes }) => campaignsColumnTypes,
    getCampaignsInitStatus: ({ campaignsInitStatus }) => campaignsInitStatus,
    getCampaignsSpottypes: state => state.campaignsSpottypes,
    getCampaignsDefaultColumns: state => state.campaignsDefaultColumns,
    getCampaignsAllWithStarSelected: state => state.campaignsAllWithStarSelected,
    getCampaignsSumVisible: state => state.campaignsSumVisible,
}

const mutations = {
    [Mutations.setCampaignsExcludedCampaignIDs]: function(state, campaignIDs) {
        for (let i = 0; i < campaignIDs.length; i++) {
            state.campaignsExcludedCampaignIDs.push(campaignIDs[i])
        }
    },
    [Mutations.setResetExcludedCampaignIDs]: state => {
        state.campaignsExcludedCampaignIDs = []
    },
    [Mutations.setCampaignsQuery]: (state, payload) => (state.campaignsQuery = payload),
    [Mutations.setCampaignsResults]: (state, payload) => (state.campaignsResults = payload),
    [Mutations.setCampaignsSelectedRowsIndex]: (state, payload) => (state.campaignsResults.data.selectedRowsIndex = payload),
    [Mutations.setCampaignsSelectedResults]: (state, payload) => (state.campaignsSelectedResults = payload),
    [Mutations.setCampaignsSummary]: (state, payload) => (state.campaignsSummary = payload),
    [Mutations.setCampaignsColumnTypes]: (state, payload) => (state.campaignsColumnTypes = payload),
    [Mutations.setCampaignsInitStatus]: (state, payload) => (state.campaignsInitStatus = payload),
    [Mutations.setCampaignsQueryForAPI]: (state, payload) => (state.campaignsQueryForAPI = payload),
    [Mutations.setCampaignsChannels]: (state, payload) => (state.campaignsChannels = payload),
    [Mutations.setCampaignsStartdate]: (state, payload) => (state.campaignsQuery.start_date = payload),
    [Mutations.setCampaignsEnddate]: (state, payload) => (state.campaignsQuery.end_date = payload),
    [Mutations.setCampaignsSpottypes]: (state, payload) => (state.campaignsSpottypes = payload),
    [Mutations.setCampaignsDefaultColumns]: (state, payload) => (state.campaignsDefaultColumns = payload),
    [Mutations.setCampaignsAllWithStarSelected]: (state, payload) => (state.campaignsAllWithStarSelected = payload),
    [Mutations.setCampaignsSumVisible]: (state, payload) => (state.campaignsSumVisible = payload),
}

export default {
    state,
    getters,
    mutations,
    actions,
}
