<template>
    <div class="main-body">
        <div class="header-subnav-container">
            <PageHeader />
            <SubNav />
        </div>
        <div class="webtool-container">
            <router-view />
            <div v-if="visibleBanner" class="alert alert-dark text-center mb-0 banner browserWarning" role="alert">
                OBS: Verktyget är optimerat för Chrome browser

                <button type="button" class="btn btn-primary btn-sm ms-3 browserBannerBtn" @click="hideBrowserBanner()">OK</button>
            </div>
        </div>
    </div>
</template>

<script>
import SubNav from "./SubNav/SubNav"
import PageHeader from "./PageHeader/PageHeader"
export default {
    name: "Layout",
    components: {
        SubNav,
        PageHeader,
    },
    data() {
        return {
            visibleBanner: false,
        }
    },
    mounted() {
        this.initializeBanner()
    },
    methods: {
        initializeBanner() {
            if (localStorage.getItem("browserBanner") == null && !this.$browserDetect.isChrome) this.visibleBanner = true
        },
        hideBrowserBanner() {
            localStorage.setItem("browserBanner", true)
            this.visibleBanner = false
        },
    },
}
</script>

<style scoped>
.main-body {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.header-subnav-container {
    flex: 0 0 auto;
}
.webtool-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    flex: 1;
}
.banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    border-radius: 0;
    background: #424851;
}

.browserWarning {
    color: red;
}

.browserBannerBtn {
    float: right;
    background: #97d058;
    border: none;
}
</style>
