import * as Mutations from "./mutation-types"
import * as Actions from "./action-types"
import * as api from "@/api/api"
import * as Helpers from "@/helpers"
import { Tabs } from "@/constants/appConstants"

const defaultChannelsQuery = {
    start_date: "",
    end_date: "",
    provider_keys: [],
    grouptime: "day",
}

const state = {
    channelsInitStatus: "NOT_LOADED",
    channelsQuery: {
        ...defaultChannelsQuery,
    },
    channelsExportQuery: {
        include_detail_rows: true,
        include_header: true,
    },
    channelsResults: {
        status: "NOT_LOADED",
    },
    channelsSummaryResults: {
        status: "NOT_LOADED",
    },
    channelsHaveSelectedRow: false,
    channelsColumnTypes: [],
    channelsDefaultColumns: [],
    channelsProviders: [],
    channelsSelectedChannelInfo: {},
}

const convertFromQueryToQueryForAPI = query => {
    // transformera interna queryObjektet till ett som passar vår API
    const storedTargets = localStorage.getItem("targetGroups") ? JSON.parse(localStorage.getItem("targetGroups")) : {}
    let targetGroupsChannels = storedTargets["getChannelsQuery"]

    const APIQuery = {
        start_date: query.start_date,
        end_date: query.end_date,
        provider_keys: query.provider_keys,
        grouptime: query.grouptime,
        measures: targetGroupsChannels,
    }
    return APIQuery
}

// hjälpfunktion för att exekvera programfrågan
const executeQuery = async (context, query) => {
    try {
        context.commit(Mutations.setChannelsExportQuery, { ...context.getters.getChannelsExportQuery, ...query })
        context.commit(Mutations.setChannelsResults, {
            status: "LOADING",
            data: null,
        })
        let r = await api.executeSiteQuery(query)
        let columns = context.getters.getChannelsQuery.columns
        // fått ett svar

        // kolla om vi fått några rader
        if (r.sites.length == 0) {
            context.commit(Mutations.setChannelsResults, {
                status: "EMPTY_RESULT",
            })
        } else {
            let levelOneSitesWithLevelTwoSites = r.sites
                .filter(s => s.isParent)
                .map((s, i) => {
                    for (let i = 0; i < s.measures.length; i++) {
                        s[`measure_${i + 1}`] = s.measures[i]
                    }
                    let levelTwoSites = r.sites
                        .filter(lv2p => lv2p.siteName === s.siteName && !lv2p.isParent && lv2p.date === s.date)
                        .map(s => {
                            for (let i = 0; i < s.measures.length; i++) {
                                s[`measure_${i + 1}`] = s.measures[i]
                            }
                            return {
                                ...s,
                                chosen: false,
                            }
                        })
                    Helpers.setLevelOneValuesForChannels(levelTwoSites)
                    return {
                        ...s,
                        levelTwoSites: levelTwoSites,
                        index: i,
                        chosen: false,
                    }
                })
            let fields = context.getters.getChannelsColumnTypes
                .filter(c => columns.includes(c.value))
                .map(c => {
                    return {
                        key: c.value,
                        label: c.text,
                        sortable: true,
                    }
                })
            for (const field of fields) {
                if (field.key === "date") {
                    field.thClass = "date-th"
                }
            }
            let fieldsMeasures = []
            for (let i = 0; i < r.measureHeaders.length; i++) {
                fieldsMeasures.push({
                    key: `measure_${i + 1}`,
                    label: `${r.measureHeaders[i].header} ${r.measureHeaders[i].subHeader}`,
                    header: r.measureHeaders[i].header,
                    subHeader: r.measureHeaders[i].subHeader,
                    sortable: true,
                    tdClass: "measureTdClass",
                })
            }
            let selectedSpecificChannel = context.getters.getChannelsSelectedChannelInfo.selectedSpecificChannel ? true : false
            let foundReachTargetGroup = query.measures.some(m => m.measure === "reach")
            let setReachAgeGroupToA3ToA99 = selectedSpecificChannel && foundReachTargetGroup
            context.commit(Mutations.setChannelsResults, {
                status: "LOADED",
                showSelectSpecificChannelError: setReachAgeGroupToA3ToA99,
                data: {
                    levelOneSitesWithLevelTwoSites: levelOneSitesWithLevelTwoSites,
                    columns: [...fields, ...fieldsMeasures],
                },
            })
        }
    } catch (error) {
        context.commit(Mutations.setChannelsResults, {
            status: "FAILED",
            data: null,
            error: error,
        })
    }
}
const executeSummaryQuery = async (context, query) => {
    try {
        context.commit(Mutations.setChannelsSummaryResults, {
            status: "LOADING",
            data: null,
        })
        let r = await api.executeSiteQuery(query)
        let columns = context.getters.getChannelsQuery.columns
        // fått ett svar

        // kolla om vi fått några rader
        if (r.sites.length == 0) {
            context.commit(Mutations.setChannelsSummaryResults, {
                status: "EMPTY_RESULT",
            })
        } else {
            let levelOneSitesWithLevelTwoSites = r.sites
                .filter(s => s.isParent)
                .map((s, i) => {
                    for (let i = 0; i < s.measures.length; i++) {
                        s[`measure_${i + 1}`] = s.measures[i]
                    }
                    let levelTwoSites = r.sites
                        .filter(lv2p => lv2p.siteName === s.siteName && !lv2p.isParent && lv2p.date === s.date)
                        .map(s => {
                            for (let i = 0; i < s.measures.length; i++) {
                                s[`measure_${i + 1}`] = s.measures[i]
                            }
                            return {
                                ...s,
                                chosen: false,
                            }
                        })
                    Helpers.setLevelOneValuesForChannels(levelTwoSites)
                    return {
                        ...s,
                        levelTwoSites: levelTwoSites,
                        index: i,
                        chosen: false,
                    }
                })
            for (let i = 0; i < r.sumRow.measures.length; i++) {
                r.sumRow[`measure_${i + 1}`] = r.sumRow.measures[i]
            }
            levelOneSitesWithLevelTwoSites.unshift(r.sumRow)
            let fields = context.getters.getChannelsColumnTypes
                .filter(c => columns.includes(c.value))
                .map(c => {
                    return {
                        key: c.value,
                        label: c.text,
                        sortable: true,
                    }
                })
            for (const field of fields) {
                if (field.key === "date") {
                    field.thClass = "date-th"
                }
            }
            let fieldsMeasures = []
            for (let i = 0; i < r.measureHeaders.length; i++) {
                fieldsMeasures.push({
                    key: `measure_${i + 1}`,
                    label: `${r.measureHeaders[i].header} ${r.measureHeaders[i].subHeader}`,
                    header: r.measureHeaders[i].header,
                    subHeader: r.measureHeaders[i].subHeader,
                    sortable: true,
                    tdClass: "measureTdClass",
                })
            }
            context.commit(Mutations.setChannelsSummaryResults, {
                status: "LOADED",
                data: {
                    levelOneSitesWithLevelTwoSites: levelOneSitesWithLevelTwoSites,
                    columns: [...fields, ...fieldsMeasures],
                },
            })
        }
    } catch (error) {
        context.commit(Mutations.setChannelsSummaryResults, {
            status: "FAILED",
            data: null,
            error: error,
        })
    }
}

const actions = {
    // hämta alla data som krävs för att visa sidan
    // uppdatera state programInitStatus för att kunna ha spinners etc vid laddning
    [Actions.getChannelsProviders]: async context => {
        const providerObjects = await api.getProviders(Tabs.ChannelTab)
        const providers = Object.keys(providerObjects).map(k => providerObjects[k])
        await context.commit(Mutations.setChannelsProviders, providers)
        return providers
    },
    [Actions.getChannelsColumnTypes]: async () => {
        return await api.getColumnTypes("SITES")
    },
    [Actions.getChannelsMeasures]: async () => {
        return await api.getMeasures("SITES")
    },

    // denna initialiserar tabellen och frågan, alltså använda då tryck på 'Sök'
    [Actions.executeChannelsQuery]: async context => {
        const query = convertFromQueryToQueryForAPI(context.state.channelsQuery)
        await executeQuery(context, query)
    },
    [Actions.executeChannelsSummaryQuery]: async context => {
        let rowkeys = context.getters.getChannelsResults.data.levelOneSitesWithLevelTwoSites
            .map(s => s.levelTwoSites)
            .reduce(function(pre, cur, i) {
                let preArray = i === 1 ? Helpers.convertToRowkeysArraySite(pre) : pre
                let rowKeysArray = Helpers.convertToRowkeysArraySite(cur)
                return preArray.concat(rowKeysArray)
            })
        const query = {
            ...convertFromQueryToQueryForAPI(context.state.channelsQuery),
            row_keys: rowkeys,
        }
        await executeSummaryQuery(context, query)
    },
    //initialiserar csv-export förfrågan för kanaler, använd vid tryck på 'Exportera'
    [Actions.executeExportChannelsQuery]: async context => {
        const query = context.getters.getChannelsExportQuery
        query.include_summary_row = false
        query.row_keys = null
        if (query.include_header) {
            query.headeritems = Helpers.getHeaderChannelTab(context, "Kanaler, lista fliken")
        }
        return await api.executeExportChannelsQuery(query)
    },
    [Actions.executeExportChannelsSummaryQuery]: async context => {
        let rowkeys = context.getters.getChannelsResults.data.levelOneSitesWithLevelTwoSites
            .map(s => s.levelTwoSites)
            .reduce(function(pre, cur, i) {
                let preArray = i === 1 ? Helpers.convertToRowkeysArraySite(pre) : pre
                let rowKeysArray = Helpers.convertToRowkeysArraySite(cur)
                return preArray.concat(rowKeysArray)
            })
        const query = context.getters.getChannelsExportQuery
        if (query.include_header) {
            query.headeritems = Helpers.getHeaderChannelTab(context, "Kanaler, summerings fliken")
        }
        query.include_summary_row = true
        query.row_keys = rowkeys
        return await api.executeExportChannelsQuery(query)
    },
}

const getters = {
    getChannelsQuery: state => state.channelsQuery,
    getChannelsExportQuery: state => state.channelsExportQuery,
    getChannelsResults: state => state.channelsResults,
    getChannelsSummaryResults: state => state.channelsSummaryResults,
    getChannelsHaveSelectedRow: state => state.channelsHaveSelectedRow,
    getChannelsColumnTypes: ({ channelsColumnTypes }) => channelsColumnTypes,
    getChannelsInitStatus: ({ channelsInitStatus }) => channelsInitStatus,
    getChannelsDefaultColumns: state => state.channelsDefaultColumns,
    getChannelsProviders: state => state.channelsProviders,
    getChannelsSelectedChannelInfo: state => state.channelsSelectedChannelInfo,
}

const mutations = {
    [Mutations.setChannelsQuery]: (state, payload) => (state.channelsQuery = payload),
    [Mutations.setChannelsExportQuery]: (state, payload) => (state.channelsExportQuery = payload),
    [Mutations.setChannelsQueryProviders]: (state, payload) => (state.channelsQuery.provider_keys = payload),
    [Mutations.setChannelsResults]: (state, payload) => (state.channelsResults = payload),
    [Mutations.setChannelsSummaryResults]: (state, payload) => (state.channelsSummaryResults = payload),
    [Mutations.setChannelsHaveSelectedRow]: (state, payload) => (state.channelsHaveSelectedRow = payload),
    [Mutations.setChannelsColumnTypes]: (state, payload) => (state.channelsColumnTypes = payload),
    [Mutations.setChannelsInitStatus]: (state, payload) => (state.channelsInitStatus = payload),
    [Mutations.setChannelsStartdate]: (state, payload) => (state.channelsQuery.start_date = payload),
    [Mutations.setChannelsEnddate]: (state, payload) => (state.channelsQuery.end_date = payload),
    [Mutations.setChannelsDefaultColumns]: (state, payload) => (state.channelsDefaultColumns = payload),
    [Mutations.setChannelsProviders]: (state, payload) => (state.channelsProviders = payload),
    [Mutations.setChannelsSelectedChannelInfo]: (state, payload) => (state.channelsSelectedChannelInfo = payload),
}

export default {
    state,
    getters,
    mutations,
    actions,
}
