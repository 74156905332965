<template>
    <div class="header" :style="bgc">
        <b-col>
            <b-row class="topheader-container">
                <h5 class="application-sub-heading">
                    <p :v-show="config_ApplicationSubHeading.length > 0" class="applicationSubHeading">
                        {{ config_ApplicationSubHeading }}
                    </p>
                </h5>
                <div class="topheader">
                    <div id="logo" class="top-header-left-flex">
                        <img src="../../../assets/images/mms_logo_white.svg" alt="" />
                    </div>
                    <div class="top-header-middle">
                        <div class="text-white webtools-horizontal">
                            <a :href="config_LinkTvAnalys" class="webtool">
                                <div class="webtool-icon-container">
                                    <img class="webtool-icon" src="../../../assets/images/tv.svg" alt="" />
                                </div>
                                <div class="webtool-text-container">
                                    <p>TV-Analys</p>
                                </div>
                                <div class="nav-selection-underline"></div>
                            </a>
                            <a :href="config_LinkOnlineAnalys" class="webtool">
                                <div class="webtool-icon-container">
                                    <img class="webtool-icon" src="../../../assets/images/online.svg" alt="" />
                                </div>
                                <div class="webtool-text-container">
                                    <p>Online-Analys</p>
                                </div>
                                <div class="nav-selection-underline"></div>
                            </a>
                            <a class="webtool">
                                <div class="webtool-icon-container">
                                    <img class="webtool-icon" src="../../../assets/images/total.svg" alt="" />
                                </div>
                                <div class="webtool-text-container">
                                    <p style="user-select: none">Totalanalys</p>
                                </div>
                                <div class="nav-selection-underline-static"></div>
                            </a>
                            <a :href="config_LinkDataPortal" class="webtool">
                                <div class="webtool-icon-container">
                                    <img class="webtool-icon" src="../../../assets/images/home.svg" alt="" />
                                </div>
                                <div class="webtool-text-container">
                                    <p>Portalen</p>
                                </div>
                                <div class="nav-selection-underline"></div>
                            </a>
                        </div>
                    </div>
                    <div class="top-header-right-flex contextmenu-container">
                        <div class="webtools-dropdown-container">
                            <b-dropdown id="dropdown-2" text="Fler Webbverktyg" class="m-2 webtools-dropdown">
                                <b-dropdown-item :href="config_LinkTvAnalys"><img class="webtool-icon-dropdown" src="../../../assets/images/tv-blue.svg" alt="" />TV-Analys</b-dropdown-item>
                                <b-dropdown-item :href="config_LinkOnlineAnalys"
                                    ><img class="webtool-icon-dropdown" src="../../../assets/images/online-blue.svg" alt="" />Online-Analys</b-dropdown-item
                                >
                                <b-dropdown-item active><img class="webtool-icon-dropdown" src="../../../assets/images/total.svg" alt="" />Totalanalys</b-dropdown-item>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item :href="config_LinkDataPortal"><img class="webtool-icon-dropdown" src="../../../assets/images/home-blue.svg" alt="" />Portalen</b-dropdown-item>
                            </b-dropdown>
                        </div>
                        <b-dropdown id="dropdown-1" split :text="this.$store.getters.getUser" class="contextmenu-login">
                            <b-dropdown-item @click="logout">Logga ut</b-dropdown-item>
                            <b-dropdown-item @click="onClick">Rensa Cache</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                <vue-context ref="menu">
                    <li>
                        <a href="#" @click.prevent="onClick($event.target.innerText)">Återställ allt</a>
                    </li>
                    <li>
                        <a href="#" @click.prevent="onClick($event.target.innerText)">Återställ kolumnval</a>
                    </li>
                    <li>
                        <a href="#" @click.prevent="onClick($event.target.innerText)">Återställ Målgruppsväljaren</a>
                    </li>
                </vue-context>
            </b-row>
            <b-row class="bottomheader">
                <div class="d-flex">
                    <div class="align-self-center">
                        <div id="versionTooltip" class="this-tool-container">
                            <div class="this-tool-icon-container">
                                <img class="this-tool-icon" src="../../../assets/images/total-blue.svg" alt="" />
                            </div>
                            <div class="this-tool-text-container">
                                <p>{{ config_ApplicationHeading }}</p>
                            </div>
                        </div>
                        <b-tooltip v-if="SSASMessage != null || config_CurrentClientVersion != null" target="versionTooltip" triggers="hover" custom-class="infoTooltip">
                            <p>Klient version: {{ config_CurrentClientVersion }}</p>
                            <p>API version: {{ SSASMessage.version }}</p>
                            <p>Modell: {{ SSASMessage.linkedSSAS }}</p>
                            <p>Senaste SSAS processning: {{ SSASMessage.lastProcessed }}</p>
                            <p>Första publiceringsdag content: {{ SSASMessage.contentPublishedFromDate }}</p>
                            <p>Sista publiceringsdag content: {{ SSASMessage.contentPublishedToDate }}</p>
                            <p>Första publiceringsdag ad: {{ SSASMessage.adPublishedFromDate }}</p>
                            <p>Sista publiceringsdag ad: {{ SSASMessage.adPublishedToDate }}</p>
                        </b-tooltip>
                    </div>
                    <p class="topBtns obsText ml-3">{{ obsMessage }}</p>
                </div>
                <div class="d-flex right mr-2">
                    <b-button class="btn-xs mx-2" variant="primary" v-b-toggle.sidebar-1><span class="informationlogtext">Informationslogg</span></b-button>
                    <b-button @click="openInfoLink" class="btn-xs manual-button" variant="info">
                        <b-icon icon="info-circle-fill"></b-icon>
                        <span>Manual</span>
                    </b-button>
                </div>
            </b-row>
        </b-col>
        <b-sidebar right id="sidebar-1" title="Informationslogg" shadow>
            <div class="px-3 py-2">
                <p class="messageBox">{{ dataLogMessage }}</p>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
import { dateToyymmddFormat, dateToTimeStamp } from "../../../api/utils.js"
import { fetchFormFieldData } from "../../../api/api.js"
import VueContext from "vue-context"
import globalConfig from "../../../mixins/globalConfig"

export default {
    mixins: [globalConfig],
    name: "PageHeader",
    components: {
        VueContext,
    },
    data() {
        return {
            info: "You are have been login and this is Home Page",
            obsMessage: "",
            dataLogMessage: "",
            SSASMessage: {
                linkedSSAS: "",
                version: "",
                lastProcessed: "",
                latestContentDay: "",
                latestAdDay: "",
                contentPublishedFromDate: "",
                contentPublishedToDate: "",
                adPublishedFromDate: "",
                adPublishedToDate: "",
                excelExportFilePreName: "",
            },
        }
    },
    async mounted() {
        await this.loadGlobalConfigFromFile()
        this.$store.commit("setApplicationHeader", this.config_ApplicationHeading)
        this.$store.commit("setClientVersion", this.config_CurrentClientVersion)

        this.$root.$on("bv::dropdown::show", bvEvent => {
            if (bvEvent.componentId === "dropdown-2") {
                this.isDropdown2Visible = true
            }
        })
        this.$root.$on("bv::dropdown::hide", bvEvent => {
            if (bvEvent.componentId === "dropdown-2") {
                this.isDropdown2Visible = false
            }
            if (this.isDropdown2Visible) {
                bvEvent.preventDefault()
            }
        })
    },
    computed: {
        bgc() {
            return {
                "background-color": this.config_BackgroundColor,
            }
        },
    },
    methods: {
        async logout() {
            await this.$store.dispatch("logout")
            window.location.href = this.config_WebCaatAuthWebUrl
        },
        openInfoLink() {
            window.open("https://mms.se/wp-content/uploads/manual-totalanalys.pdf", "_blank")
        },
        toastMessage(message, closeButton) {
            if (closeButton) {
                const h = this.$createElement

                const vNodesMsg = h("div", [
                    h("p", { class: ["mb-0"] }, "Problem att hämta standarddatum från servern. Tjänsten kommer inte fungera korrekt."),
                    h(
                        "b-button",
                        {
                            class: ["closebtn", "btn-sm"],
                            on: {
                                click: () => this.$bvToast.hide("toast"),
                            },
                        },
                        "Stäng"
                    ),
                ])

                this.$bvToast.toast([vNodesMsg], {
                    id: "toast",
                    title: "Fel",
                    variant: "danger",
                    solid: true,
                    noCloseButton: true,
                    noAutoHide: true,
                })
            } else {
                this.$bvToast.toast(message, {
                    title: "Varning",
                    variant: "warning",
                    solid: true,
                    noCloseButton: false,
                })
            }
        },
        onClick() {
            var accessToken = localStorage.getItem("accessToken")
            var user = localStorage.getItem("user")
            localStorage.clear()
            localStorage.setItem("accessToken", accessToken)
            localStorage.setItem("user", user)
            location.reload()
        },
    },
    async created() {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        let token = urlParams.get("token")
        let user = urlParams.get("user")

        if (token === null) {
            token = localStorage.getItem("accessToken", token)
            user = localStorage.getItem("user", user)
        }

        localStorage.setItem("accessToken", token)
        localStorage.setItem("user", user)

        this.$store.commit("setLoggedIn", true)
        this.$store.commit("setUser", user)
        this.$store.commit("setAccessToken", token)

        await fetchFormFieldData("User")
            .then(user => {
                localStorage.setItem("user", user.email)
                this.$store.commit("setUser", user.email)
                for (let i = 0; i < user.applications.length; i++) {
                    if (user.applications[i].applicationName == "total-analys") {
                        this.$store.commit("setFullAccess", true)
                    }
                }
            })
            .catch(error => {
                if (error.response?.status != 401) this.toastMessage("Problem uppstod vid hämtning av användare.", false)
                console.error(error)
            })
        //Fetch Message from api
        await fetchFormFieldData("Message")
            .then(messages => {
                if (messages.statusCode) {
                    this.toastMessage(messages.errorMessage, false)
                } else {
                    this.obsMessage = messages.obsMessage
                    this.dataLogMessage = messages.dataLogMessage
                }
            })
            .catch(error => {
                if (error.response?.status != 401) this.toastMessage("Problem uppstod vid hämtning av meddelanden.", false)
                console.error(error)
            })

        //Fetch Version from api
        await fetchFormFieldData("Information")
            .then(information => {
                if (information.statusCode) {
                    this.toastMessage(information.errorMessage, false)
                } else {
                    this.SSASMessage.linkedSSAS = information.linkedSSAS
                    this.SSASMessage.version = information.version
                    this.SSASMessage.lastProcessed = dateToyymmddFormat(information.lastProcessed.replace(/-/g, "/")) + " " + dateToTimeStamp(information.lastProcessed.replace(/-/g, "/"))

                    this.SSASMessage.contentPublishedFromDate = dateToyymmddFormat(information.contentPublishedFromDate.replace(/-/g, "/"))
                    this.SSASMessage.contentPublishedToDate = dateToyymmddFormat(information.contentPublishedToDate.replace(/-/g, "/"))

                    this.SSASMessage.adPublishedFromDate = dateToyymmddFormat(information.adPublishedFromDate.replace(/-/g, "/"))
                    this.SSASMessage.adPublishedToDate = dateToyymmddFormat(information.adPublishedToDate.replace(/-/g, "/"))

                    this.$store.commit("setMetadataAdToDate", this.SSASMessage.metadataAdToDate)
                    this.$store.commit("setExcelExportFilePreName", information.excelExportFilePreName)
                    this.$store.commit("setMetadataProgramToDate", this.SSASMessage.contentPublishedToDate)
                    this.$store.commit("setPublishedFromDate", this.SSASMessage.contentPublishedFromDate)
                    this.$store.commit("setPublishedToDate", this.SSASMessage.contentPublishedToDate)
                    this.$store.commit("setApiVersion", information.version)
                }
            })
            .catch(error => {
                if (error.response?.status != 401) this.toastMessage("Problem att hämta standarddatum från servern. Tjänsten kommer inte fungera korrekt.", true)
                console.error(error)
            })
    },
}
</script>

<style scoped>
.header {
    background-color: #337ab7;
    height: 110px;
}
.topheader-container {
    height: 70px;
    padding: 0 20px;
    align-content: center;
}
.topheader {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.application-sub-heading {
    position: absolute;
    top: 52px;
}
.top-header-left-flex {
    width: 45%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
}
.top-header-right-flex {
    width: 45%;
    display: flex;
    justify-content: flex-end;
}
.top-header-middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 500px;
}
.bottomheader {
    background-color: white;
    height: 39px;
    padding: 5px;
    padding-left: 16px;
}
.top-header-left-flex img {
    width: 8em;
}
.header p,
.header a {
    color: white;
    margin-bottom: 0;
}
.informationlogtext {
    font-size: 0.8em;
}
.topBtns {
    float: right;
    color: white;
}
.helpIcon {
    margin-bottom: 10px;
}

.obsText {
    color: #ff8c00 !important;
    font-size: 0.9em;
    align-self: center;
}
.messageBox {
    border: 1px solid #dee2e6 !important;
    background-color: #eee;
    margin-bottom: 0;
    padding: 10px;
    font-size: small;
    color: #2c3e50 !important;
    text-align: left;
    white-space: pre-line;
}
.applicationSubHeading {
    font-size: small;
    color: white !important;
    -webkit-text-stroke: 0.04em red;
    font-weight: bold;
}
.btn-xs {
    padding: 0.25rem 0.4rem;
    font-size: 0.875rem;
    line-height: 0.5;
    border-radius: 0.2rem;
}
.right {
    position: absolute;
    right: 10px;
}
.v-context a {
    color: #495057;
}
</style>
<style>
.closebtn {
    position: absolute;
    right: 0;
    bottom: 0;
}
.infoTooltip .tooltip-inner {
    font-size: smaller;
    text-align: left;
    max-width: 300px;
}
.contextmenu-login > button:first-child:hover {
    border-color: #6c757d;
    cursor: default;
}
.top-header-right-flex button {
    background-color: inherit !important;
    /* border: none; */
}
#dropdown-1 {
    max-height: 40px;
    align-self: center;
}
.webtools-dropdown-container {
    border: none !important;
    margin: 0;
    padding: 0;
}
.webtools-dropdown button {
    border: none !important;
    border-color: transparent !important;
    padding-left: 1rem;
}
.webtools-dropdown ul {
    font-size: 0.8rem;
}
.webtool a {
    font-size: 0.8rem;
    white-space: nowrap;
    align-self: center;
}
.webtool p {
    font-size: 0.8rem;
    white-space: nowrap;
    align-self: center;
    margin-top: 0.2rem;
}
.this-tool-container {
    position: relative;
    padding: 0;
    margin-right: 3rem;
    align-self: center;
    display: inline-flex;
    align-content: center;
}
.this-tool-text-container p {
    color: #337ab7 !important;
    font-weight: 500;
    font-size: 1rem;
    padding: 0;
    height: 100%;
    margin-top: 0.2rem;
}
.webtool:hover {
    text-decoration: none;
}
.webtool-icon-container {
    flex: 1;
    height: 100%;
    width: 2rem;
    margin-right: 0.3rem;
}
.this-tool-icon-container {
    flex: 1;
    height: 100%;
    width: 2.4rem;
    margin-top: 0.1rem;
}
.webtool-icon-container img {
    max-width: 1.5rem;
    max-height: 1.5rem;
}
.this-tool-icon-container {
    max-width: 2.5rem;
    max-height: 2.5rem;
    margin-top: 0;
}
.webtool-text-container {
    flex: 1;
    text-align: center;
    margin-top: 0.2rem;
}
.nav-selection-underline-static {
    position: absolute;
    width: 115%;
    bottom: -0.5rem;
    transform: translateX(-5%);
    border-bottom: white 1px solid;
}
.nav-selection-underline {
    transition: background-color 100ms ease-in-out;
    background-color: transparent;
}
.webtool:hover .nav-selection-underline {
    position: absolute;
    width: 115%;
    bottom: -0.6rem;
    transform: translateX(-5%);
    height: 2.8rem;
    background-color: #19508131;
    z-index: -1;
    border-radius: 5%;
}
.webtool-icon-dropdown {
    max-width: 1.5rem;
    max-height: 1.5rem;
    margin-right: 0.8rem;
    margin-bottom: 0.15rem;
}
img.this-tool-icon {
    max-width: 1.5rem;
    max-height: 1.5rem;
    margin-left: 0.2rem;
    margin-right: 0.4rem;
    padding: 0;
}
#dropdown-2 {
    display: none;
}
.webtool {
    position: relative;
    margin: 0;
    padding: 0;
    align-self: center;
    display: inline-flex;
    align-content: center;
}
.webtools-horizontal {
    display: flex;
    justify-content: space-between;
    align-self: center;
    padding: 0;
    margin: 0;
    height: 38px;
    width: 100%;
}
.webtool-icon-container {
    flex: 1;
    height: 100%;
}
.manual-button {
    display: inline-flex !important;
    align-content: center;
}
.manual-button span {
    flex: 1;
    padding-left: 0.5rem;
    font-size: 0.7rem !important;
    align-self: center;
}
.manual-button .b-icon {
    flex: 1;
}
@media (max-width: 639px) {
    .webtools-horizontal {
        display: none;
    }
}
@media (min-width: 640px) and (max-width: 1200px) {
    #dropdown-2 {
        display: block;
    }
    .webtools-horizontal {
        display: none;
    }
}
</style>
